import { useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { TID } from '@src/types/common';
import { fetchLDBusinessFeature, fetchLDFeatureFlagValueAndCached } from '@src/utils/config';

const useLDBusinessFeatureQuery = (businessId?: TID, feature?: string) => {
  return useQuery(
    [QueryKey.ldBusinessFeature, { businessId, feature }],
    () => fetchLDBusinessFeature(String(businessId), feature!),
    {
      enabled:     !!(businessId && feature), // don't to any query if one this is not provided
      placeholderData: false, // The feature is disable by default
    },
  );
};

const useLDManagementGroupFeatureQuery = (managementGroupId: TID, feature: string) => {
  return useQuery(
    [QueryKey.ldBusinessFeature, { managementGroupId, feature }],
    () => fetchLDFeatureFlagValueAndCached(feature, String(managementGroupId), 'management-group'),
    {
      enabled:     !!(managementGroupId && feature),
      placeholderData: false,
    },
  );
};

const useLDUserFeatureQuery = (userId?: TID, feature?: string, initialData?: boolean) => {
  return useQuery(
    [QueryKey.ldUserFeature, { userId, feature }],
    () => fetchLDFeatureFlagValueAndCached(feature!, String(userId), 'user'),
    {
      enabled:     !!(userId && feature),
      placeholderData: initialData || false,
    },
  );
};

export {
  useLDBusinessFeatureQuery,
  useLDManagementGroupFeatureQuery,
  useLDUserFeatureQuery,
};
